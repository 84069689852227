import { CreatedPost } from "@/views/posts-planner/posts.types";

import { PostState } from "piramis-base-components/src/shared/modules/posting/types";

import moment from "moment";
import { EventInput } from "@fullcalendar/vue";

export function postObjectFabric(date: string, post: CreatedPost): EventInput {
  return {
    extendedProps: {
      ...post,
      postTime:date
    },
    id: post.key,
    start: date,
    end: moment(date).add(1, 'second').toDate(),
    allDay: false,
    className: postColor(post),
  }
}

export function postColor(post: CreatedPost) {
  if (post.run_time !== undefined) {
    if (post.state === PostState.Complete) {
      return [ 'complete' ]
    } else if (post.run_time.length > 1 || post.schedule?.period && moment(post.schedule.run_time).isBefore(moment())) {
      return [ 'repeat' ]
    } else if (post.state === PostState.Active && post.run_time.length === 1) {
      return [ 'regular' ]
    } else if (post.state === PostState.Error) {
      return [ 'error' ]
    } else {
      return [ 'normal' ]
    }
  } else {
    return [ 'regular' ]
  }
}

export function setDatesPosts(dates: Array<string>, post: CreatedPost) {
  return dates.reduce((acc: Array<EventInput>, value) => {
    acc.push(postObjectFabric(value, post))

    return acc
  }, [])
}
