export default {
  methods: {
    async getChatPropertiesSelectOptions() {
      if(this.$store.state.chatState.chat.config.property_items.length === 0) {
	return []
      }
      return this.$store.state.chatState.chat.config.property_items.map(prop => {
	return {
	  label: prop.name,
	  value: prop.name,
	};
      });
    },
  },
};
