import { SelectOptionData } from "piramis-base-components/src/components/Pi/types";
import { SelectOption } from "piramis-base-components/src/logic/types";

import Vue from "vue";
import { Component } from "vue-property-decorator";
import { BaseTriggerSettings } from "@/components/TriggerSetup/logic/types/types";
import { mapTriggerOptions } from "@/includes/logic/Triggers";
import { getNetworkTriggers } from "@/includes/logic/Networks/logic";

@Component
export default class TriggersMixin extends Vue {
  get taggedTriggers(): Array<SelectOptionData> {
    let activeChatTriggers = this.getFilteredChatTriggers("active");
    let disabledChatTriggers = this.getFilteredChatTriggers("disabled");

    activeChatTriggers = activeChatTriggers.map((trigger: any) => {
      return {
        label: trigger.name,
        value: trigger.id,
        tags: [
          {
            text: this.$t("option_trigger_active"),
            color: "rgba(var(--a-success), 1)",
          },
        ],
      };
    })

    disabledChatTriggers = disabledChatTriggers.map((trigger: any) => {
      return {
        label: trigger.name,
        value: trigger.id,
        tags: [
          {
            text: this.$t("option_trigger_disabled"),
            color: "rgba(var(--a-danger), 1)",
          },
        ],
      };
    });

    return [
      ...activeChatTriggers,
      ...disabledChatTriggers,
    ];
  }

  // используются при выгрузке конфига чата - добавляются тэги
  async getTaggedTriggerOptions() {
    if (this.$store.state.triggersState.frontendTriggers === null) {
      await this.$store.dispatch('updateTriggers')
    }

    return this.taggedTriggers
  }

  get getActiveChatTriggersIds(): Array<string> {
    return this.$store.state.chatState.chat.triggers.slice();
  }

  get activeTriggersOptions() {
    if (this.$store.getters.isChatSet) {
      return mapTriggerOptions(this.getFilteredChatTriggers('active'));
    } else if (this.$store.state.networksState.currentNetwork) {
      return mapTriggerOptions(this.$store.state.networksState.triggers.triggers ?? [])
    }

    return []
  }

  async resolveTriggersOptions() {
    if (this.$store.getters.isChatSet) {
      if (this.$store.state.triggersState.frontendTriggers === null) {
        await this.$store.dispatch('updateTriggers')
      }

      return this.activeTriggersOptions;
    } else if (this.$store.state.networksState.currentNetwork) {
      const { networksState } = this.$store.state

      if (networksState.triggers.triggers === null) {
        await getNetworkTriggers(networksState.currentNetwork.id)
      }

      return mapTriggerOptions(networksState.triggers.triggers)
    }

    return []
  }

  sortByActiveTriggerExecutionOrder(a:any, b:any) {
    return this.getActiveChatTriggersIds.indexOf(a.id) - this.getActiveChatTriggersIds.indexOf(b.id)
  }

  getChatFrontendTriggers() {
    if (this.$store.state.triggersState.frontendTriggers) {
      return this.$store.state.triggersState.frontendTriggers
          .slice()
          .sort((a: any, b: any) => this.sortByActiveTriggerExecutionOrder(a, b) || a.name.localeCompare(b.name));
    } else {
      return [];
    }
  }

  getFilteredChatTriggers(type: 'active' | 'disabled') {
    const activeTriggersIds = this.getActiveChatTriggersIds;

    return this.getChatFrontendTriggers()
        .filter((item: any) => {
          if (type === "active") {
            return activeTriggersIds.includes(item.id);
          }
          if (type === "disabled") {
            return !activeTriggersIds.includes(item.id);
          }
        });
  }
}
