import { RawTriggerData } from "@/components/TriggerSetup/logic/types/types";

import { SelectOption } from "piramis-base-components/src/logic/types";

export function mapTriggerOptions(triggers: Array<RawTriggerData>): Array<SelectOption> {
  return triggers
      .map((t) => ({
        label: t.name,
        value: t.id
      }))
}
