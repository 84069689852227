export default {
  computed  : {
    chat() {
      return this.$store.state.chatState.chat;
    },
    runtime_config() {
      return this.$store.state.AdminTemplate.runtime_config;
    },
    placeholder() { return this.$t("enter_text");},
  },
  methods   : {
    isMobile() {
      return window.mobilecheck();
    },
  },
};
