import buttonView from './ActionButton.vue'
import setupView from './ActionButtonSetupView.vue'
import Button, { ButtonStruct }  from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/buttons'
import { Guid } from "guid-typescript";
import { cloneDeep } from 'lodash';

export default class Action extends Button {
    name = 'Action'

    setupView = setupView
    buttonView = buttonView

    data: { text: string, action: number } = this.getRawData()

    structBuilder = ActionButtonStruct

    icon = '🔓'

    getRawData(): { text: string, action: number } {
        return {
          text: this.$i18n.t('initial_text_message_editor_buttons').toString(),
          action: 1
        }
    }

  create(): ButtonStruct {
    const struct = new ActionButtonStruct({
      ...cloneDeep(this.data),
      guid: Guid.create().toString(),
      type: this.name,
      action: 1,
    })

    this.refreshData()

    return struct
  }
}

export class ActionButtonStruct extends ButtonStruct {
  action: number

  constructor(struct: ActionButtonStruct) {
    super(struct)

    const { action } = struct
    this.action = action
  }
}

