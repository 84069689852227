






import { Component, Prop } from 'vue-property-decorator'
import Vue from 'vue'
import DefaultButtonView from 'piramis-base-components/src/components/MessageEditorWithMedia/components/ButtonsConstructor/components/DefaultButtonView/DefaultButtonView.vue'

@Component({
  components: {
    DefaultButtonView
  }
})
export default class ActionButton extends Vue {
  @Prop({ type: String }) readonly text!: string
}
