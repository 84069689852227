var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"check-button-setup-view"},[_c('text-input',{attrs:{"setup":{
      'func': _vm.modelSetter,
      'args': {
        model: _vm.model,
        'key': 'text',
        'prefix': 'action_button_',
        'validation': 'required',
      },
    }}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }