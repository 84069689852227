// import TriggerCallButtonBuilder from "@/components/ProjectButtons/NewButtons/TriggerCall";
import i18n from "@/i18n/i18n";

import ShareButtonBuilder
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Share";
import ReactionButtonBuilder
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Reaction";
import PalmReactionButtonBuilder
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/PalmReaction";
import FingerUpReactionButtonBuilder
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerUpReaction";
import FingerDownReactionButtonBuilder
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/FingerDownReaction";
import LinkButtonBuilder
  from "piramis-base-components/src/components/NewMessageEditors/NewMessageEditorWithMedia/ButtonsConstructor/Buttons/Link";

export function defaultButtonBuilders() {
  return [
    // new TriggerCallButtonBuilder(i18n, this.resolveTriggersOptions),
    new ShareButtonBuilder(i18n),
    new ReactionButtonBuilder(i18n),
    new PalmReactionButtonBuilder(i18n),
    new FingerUpReactionButtonBuilder(i18n),
    new FingerDownReactionButtonBuilder(i18n),
    new LinkButtonBuilder(i18n),
  ]
}
