<template>
  <fullscreen-layout
    :active="isMobile() && $screen.width < 768 && $screen.portrait"
  >
    <div class="">
      <img
        :src="require('@/assets/images/orientation/to-horisontal.gif')"
        height="200"
        alt=""
      >
    </div>
  </fullscreen-layout>
</template>
<script>
import FullscreenLayout from "./FullscreenLayout";
import isMobile from 'piramis-js-utils/lib/isMobile'

export default {
  name      : "MobileLandscapeTriggerLayout",
  components: { FullscreenLayout },
  data() {
    return {
      isMobile
    }
  }
};
</script>
